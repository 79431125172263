.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.content {
  overflow-y: auto;
  padding: 24px;
  padding-top: 30px;
  min-height: 280px;
  position: relative;
}
.content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../assets/images/login-bg.png');
  transform: scaleX(-1) scaleY(-1);
  filter: blur(40px) grayscale(40%);
}

.module-inside {
  position: relative;
}

#login-container {
  overflow-y: auto;
}
